import React from 'react';
import './styles/index.css';
import './styles/footer.css'

const Footer = () => (
  <footer id="sticky-footer">
    <div id="footer-container">
      <div>
        <ul className="links">
          <b>Ссылки</b><br />
          <li><a className="link-yt" href="https://www.youtube.com/@mtv-media">YouTube-канал</a></li>
          <li><a className="link-tg" href="https://t.me/kanal_mtv2019">Telegram-канал</a></li>
          <li><a className="link-vk" href="https://vk.com/mtv_media">Группа ВК</a></li>
        </ul>
        <ul className="add-links">
          <b>Дополнительно</b><br />
          <li><a className="link-pwa" href="https://pwa.mtv-media.ru/">Приложение МТВ (Бета)</a></li>
          <li><a className="link-test" href="/test.html">Тест</a></li>
        </ul>
      </div>
    </div>
    <div className="copyright">
      <h5>&copy;2019-2024, mtv-media</h5>
    </div>
  </footer>
);

export default Footer;