import React from 'react';
import './styles/404.css';

const NotFound = () => {
  return (
    <div class="error-message">
            <h1 class="logo">МТВ</h1>
            <h2>Ошибка 404</h2>
            <p>Страница не найдена 😔</p>
            <a class="home-button" href="/main">На главную</a>
            {/* <!-- Дополнительный текст или информация об ошибке --> */}
        </div>
  );
};

export default NotFound;