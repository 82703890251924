import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Main from './main';
import NotFound from './404';
import CringeLounge from './cringe-lounge';
import './styles/App.css';
import './scripts/scroll'
import './scripts/headerMenu'
import './scripts/themeSwitch'

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<Navigate to="/main" />} />
            <Route path="/main" element={<Main />} />
            {/* Uncomment and add the necessary imports for these routes */}
            <Route path="/cringe-lounge" element={<CringeLounge />} />
            {/* <Route path="/new-site" element={<AboutPage />} /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;