import React from "react";

const CringeLounge = () => {
  return (
    <main className="content">
      <b>
        <p style={{ textIndent: '20px' }}>
          <a 
            href="https://www.youtube.com/@cringe-lounge" 
            style={{ textDecoration: 'none', color: '#0D47A1' }} 
            className="link1" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Cringe lounge
          </a> - это новый развлекательный проект МТВ, открытый вместо устаревших квадратных новостей, они ушли в историю.
        </p>    
        <p style={{ textIndent: '20px' }}>
          Суть проекта заключается в создании постабсурдных комедийных видео и пародий, в которых не знаешь чего ожидать на этот раз.
        </p>
      </b>
    </main>
  );
};

export default CringeLounge;
