import React from 'react';
import './styles/header.css'

const Header = () => (
  <header className="header">
    <div className="header_body">
      <b><a className="main no-select" href="/main">Канал МТВ</a></b>
      <b><a className="main_small no-select" href="/main">МТВ</a></b>
      <div className="header_burger">
        <span></span>
      </div>
      <nav className="header_menu">
        <ul className="header_list no-select">
          <li><h3><a className="header_link" href="/cringe-lounge">Cringe lounge</a></h3></li>
          <li><h3><a className="header_link" href="/new-site">Новый сайт: FAQ</a></h3></li>
          <li><h3><a className="header_link" href="https://old.mtv-media.ru/" target="_blank" rel="noreferrer">Старая версия сайта</a></h3></li>
          <div className="theme-switch">
            <button id="theme-toggle-btn">
              <svg id="mySvg" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px"><rect fill="none" height="24" width="24"/><path d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"/></svg>
            </button>
            <span className="tooltiptext">Сменить тему сайта</span>
          </div>
        </ul>
      </nav>
    </div>
  </header>
);

export default Header;