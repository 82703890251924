import React from 'react';
import './styles/main.css';
import mongu from './images/mongu.webp';

const Main = () => {
  return (
    <main className="content">
      <div className="message">
        <h1 style={{ textAlign: 'center' }}>Скоро тут что-то будет. Следите за новостями.</h1>
        <a className="tg-channel" href="https://t.me/kanal_mtv2019" target="_blank" rel="noopener noreferrer">Telegram-канал</a>
        <h3 style={{ paddingTop: '20px' }}>А пока посмотрите на этого котика. :&#41;</h3>
        <img src= {mongu} alt="Cat" height="300" width="300" style={{ paddingTop: '10px' }} />
      </div>
    </main>
  );
};

export default Main;