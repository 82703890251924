let lastScrollTop = 0;

export const handleScroll = () => {
    const header = document.querySelector('header');
    const currentScroll = window.scrollY || document.documentElement.scrollTop;

    if (currentScroll > lastScrollTop) {
        header.style.top = '-80px'; // Скрываем хедер при скролле вниз
    } else {
        header.style.top = '0'; // Показываем хедер при скролле вверх
    }

    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // Определение направления скролла
};

window.addEventListener('scroll', handleScroll);
